.selectWrapper {
  margin-top: var(--st-sizing-md);
  max-width: 100%;

  @media (--breakpoint-small-min) {
    max-width: 50%;
  }
}

.minHeightContainer {
  display: flex;
  align-items: center;
  min-height: 3.5rem; /* 56px */

  @media (--breakpoint-large-max) {
    min-height: unset;
  }
}

.moreInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.spacedText {
  margin-right: var(--st-sizing-xxs);
  margin-bottom: var(--st-sizing-3xs);
}

.styledToolTip {
  margin-left: var(--st-sizing-xs);
  & p {
    text-transform: lowercase;
  }

  & p:first-letter {
    text-transform: uppercase;
  }
}

.noMarginTopText {
  margin-top: 0;
}
