.price {
  display: grid;
  grid-area: Price;
  text-align: center;
  justify-content: flex-end;

  @media (--breakpoint-medium-max) {
    text-align: right;
  }
}

.semiBold {
  & span {
    font-weight: var(--st-font-weight-semi-bold);
  }
}

.normal {
  & span {
    font-weight: var(--st-font-weight-normal);
  }
}
