.productImage {
  grid-area: Image;
  max-width: 5rem;
  width: 100%;

  @media (--breakpoint-medium-max) {
    justify-self: flex-start;
    max-width: 7rem;
  }
}
