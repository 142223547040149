.emailContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.changeEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 21.875rem; /* 350px */
  top: var(--st-sizing-xl);
}

.emailText {
  word-break: break-word;
}

.spacedText {
  margin-right: var(--st-sizing-xxs);
  margin-bottom: var(--st-sizing-3xs);
}

.editLink {
  margin-left: var(--st-sizing-ms);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: var(--st-sizing-lg);

  & button:first-child {
    margin-right: var(--st-sizing-ms);
  }
}
