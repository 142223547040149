.sidebar {
  list-style-type: none;
  border: var(--ds-border-width-xs) solid var(--ds-color-border-neutral-subdued);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--ds-spacing-xs);

  @media (--ds-breakpoint-md) {
    margin-top: var(--ds-spacing-xl);
    max-width: 20rem;
    width: 25%;
    height: 100%;
  }
}

.menuItem {
  margin: 0;
  display: block;

  &:not(:last-child) {
    border-bottom: var(--ds-border-width-xs) solid
      var(--ds-color-border-neutral-subdued);
  }
}

.link {
  display: flex;
  gap: var(--ds-spacing-xs);
  padding: var(--ds-spacing-xs);
  color: var(--ds-color-text-default);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.active {
  background-color: var(--ds-color-action-primary-enabled);
  color: var(--ds-color-text-inverse);
}
