.orderHistory {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-xs);
}

.navigationContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-bottom: var(--st-sizing-sm);
}
