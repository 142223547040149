.spacedText {
  margin-right: var(--st-sizing-xxs);
  margin-bottom: var(--st-sizing-3xs);
}

.changeAccountEmailContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
