.contentContainer {
  display: flex;
  flex-direction: row;
  @media (--breakpoint-small-max) {
    margin-bottom: var(--st-sizing-sm);
  }
}

.innerContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--st-sizing-xs);
  @media (--breakpoint-small-max) {
    margin-bottom: unset;
    flex-direction: column;
  }
}
