.pageContainer {
  min-height: 80vh;

  &.white {
    background-color: var(--st-color-white);
  }

  &.grey {
    background-color: var(--st-color-grey-extra-light);
  }
}
