.savedCardsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--st-sizing-3xs);
  @media (--breakpoint-small-max) {
    flex-direction: column;
    align-items: unset;
  }
}
