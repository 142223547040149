/* 
    MOBILE
 
    +---------+---------+---------+---------+---------+
    |                   |                   Item name |
    +---------+---------+---------+---------+---------+
    |                   |         |               Sku |PAB sub-item                             
    +---------+---------+---------+---------+---------+
    |      Image        |         |               Qty |
    +---------+---------+--------+----------+---------+
    |                             |              Price|
    +---------+---------+--------+----------+---------+ 

      DESKTOP
    +---------+---------+---------+---------+---------+---------+---------+---------+
    | Image   |    Item name                |Sku      |         | Qty     |    Price|   PAB sub-item
    +---------+---------+---------+---------+---------+---------+---------+---------+
    

 */

.pabSubItem {
  grid-template-areas: 'Image Name Name Name Sku Sku Quantity Price';
  grid-template-columns: repeat(8, 1fr);
  align-items: center;

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(5, 1fr);
    justify-items: end;
    grid-template-areas:
      'Image Image Name Name Name'
      'Image Image . Sku Sku'
      'Image Image . Quantity Quantity'
      'Image Image . Price Price';
  }
}
