.signUpTextOne {
  line-height: var(--st-line-height-s1);
  margin-bottom: var(--st-sizing-4xs);
  margin-right: var(--st-sizing-xl);
  align-self: flex-start;

  @media (--breakpoint-medium-max) {
    line-height: var(--st-line-height-sm1);
    margin-top: var(--st-sizing-3xs);
    margin-right: 0;
  }
}

.signUpTextTwo {
  line-height: var(--st-line-height-sm1);
  margin-right: var(--st-sizing-xl);
  margin-bottom: var(--st-sizing-3xs);
  align-self: flex-start;

  & a {
    text-decoration: underline;
    text-decoration-color: var(--st-color-black);
  }

  @media (--breakpoint-medium-max) {
    margin-bottom: 0;
    margin-right: 0;
  }
}
