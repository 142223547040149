.modalWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--st-sizing-3xs) var(--st-sizing-sm) var(--st-sizing-sm)
    var(--st-sizing-sm);
  max-width: 500rem;
  border-radius: 5px;
  margin: 0 auto;
  overflow-wrap: break-word;
  max-width: 100%;
}

.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--st-sizing-sm) 0px;
}
