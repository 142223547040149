.paymentTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--st-sizing-4xs) 0;
}

.paymentBrandText {
  margin-left: var(--ds-spacing-3xs);
}
