.preferenceCheckBoxContainerAccordionSection {
  background-color: var(--st-color-grey-extra-light);
}

.textDescMarkup {
  font-size: 13px;
}

.textDescMarkup a {
  font-weight: 100;
}

.layoutSection,
.layoutSection > div,
.layoutSection > section {
  background-color: var(--st-color-grey-extra-light) !important;
   & > section {
       padding: 1% !important;
   }
}

.accordionTitle {
  border-bottom: 0 !important;
}
