.cardDetailsSection {
  display: flex;
}

.cardDetail {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--st-color-yellow);
  padding: var(--st-sizing-xxs);
}

.cardDetailRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: var(--st-sizing-xxs);
  background-color: var(--st-color-red);
  color: white;

  @media (--breakpoint-min-large) {
    border-radius: 0 var(--st-sizing-4xs) 0 0;
  }
}

.linkContainer {
  display: flex;
  padding-top: var(--st-sizing-sm);
  gap: calc(var(--st-sizing-xs) / 2);
  justify-content: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
