.container {
  width: 100%;
  display: flex;
  margin: var(--st-sizing-md) 0px;
  @media (--breakpoint-medium-max) {
    flex-direction: column;
  }
}

.checkbox {
  width: 100%;
  display: flex;
}
