.spinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.accordionContent {
  background-color: var(--st-color-white);
  padding: var(--st-sizing-md);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (--breakpoint-small-max) {
    flex-direction: column;
  }
}

.buttonIcon {
  margin-left: 10px;
}

.productGroupItemContainer {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  padding: var(--st-sizing-xs) 0;

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.preferenceCardContainer {
  background-color: var(--st-color-grey-extra-light);
  padding: var(--st-sizing-sm);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: var(--st-sizing-sm) 0;
}

.preferenceCheckBoxContainer {
  background-color: var(--st-color-grey-extra-light);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: var(--st-sizing-sm) 0;
}
