.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--ds-spacing-xs);

  --imageSize: 96px;
  --backgroundSize: 85px;

  @media (--ds-breakpoint-md) {
    gap: var(--ds-spacing-md);

    --imageSize: 128px;
    --backgroundSize: 115px;
  }
}

.imageWrapper {
  width: var(--imageSize);
  height: var(--imageSize);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  position: relative;
}

.imageBackground {
  background-color: var(--ds-color-core-purple-1100);
  width: var(--backgroundSize);
  height: var(--backgroundSize);
  border-radius: 100%;
  position: absolute;
  bottom: 0;
}

.image {
  max-width: unset;
  height: var(--imageSize);
  position: absolute;
  bottom: 0;
  border-bottom-left-radius: var(--backgroundSize);
  border-bottom-right-radius: var(--backgroundSize);
}

.textWrapper {
  display: flex;
  flex-direction: column;
}
