.banner {
  background: var(--st-color-red-extra-light);
  margin: var(--st-sizing-3xs) 0;
  padding: var(--st-sizing-3xs) var(--st-sizing-3xs);
  border: 1px solid var(--st-color-red);
  border-radius: var(--st-sizing-4xs);

  @media (--breakpoint-small-min) {
    margin-bottom: var(--st-sizing-ms);
  }
}

.error {
  border-color: var(--st-color-red);
  background: var(--st-color-red-extra-light);
}

.warning {
  border-color: var(--st-color-yellow);
  background: var(--st-color-yellow-extra-light);
}
