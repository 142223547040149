.pabStatusDetails {
  display: flex;
  align-items: center;
  gap: var(--st-sizing-3xs);
}

.pabStatus {
  display: flex;
  justify-content: space-between;

  @media (--breakpoint-medium-max) {
    flex-direction: column;
    gap: var(--st-sizing-3xs);
  }
}

.chevronAccordion {
  display: flex;
  align-items: end;
  gap: var(--st-sizing-sm);

  @media (--breakpoint-medium-max) {
    justify-content: space-between;
  }

  & span {
    color: var(--st-color-blue);
    font-weight: 700;
  }
}

.chevron {
  margin-bottom: var(--st-sizing-4xs);
}
