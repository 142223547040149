.wrapper {
  width: fit-content;
}

.onSaleWrapper {
  composes: wrapper;
}

.styledText {
  display: flex;
  flex-direction: row;
  gap: var(--ds-spacing-3xs);
  align-items: center;
  flex-wrap: wrap;
}

.label {
  margin-top: 1px;
}

.inline {
  display: flex;
  align-items: baseline;
}

.inlineOnSale {
  composes: inline;

  .styledText {
    margin-right: var(--ds-spacing-2xs);
  }
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.textRed {
  color: var(--ds-color-text-negative);
}

.onSaleListPrice {
  text-decoration: line-through;
}

.rowWrapper {
  display: flex;
  gap: var(--ds-spacing-3xs);
  flex-direction: row;
  align-items: center;
}

.columnWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-5xs);
}

/* Insiders Pricing */
.insidersText {
  color: var(--ds-color-core-purple-900);
}

.insidersTag {
  margin-top: var(--ds-spacing-5xs);
}

.insidersMessage {
  display: flex;
  gap: var(--ds-spacing-4xs);
}

.insidersValidityText {
  color: var(--ds-color-text-subdued);
  max-width: 8rem; /* No Skroll token for this size */
}

.bundleSpecialOfferText {
  color: var(--ds-color-text-subdued);
}

.bundleOriginalPrice {
  display: block;
}
