.wishlistInformation {
  display: flex;
  margin-top: var(--st-sizing-xs);
  flex-direction: column;
  justify-content: flex-end;

  @media (--breakpoint-small-max) {
    flex: unset;
    width: unset;
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--ds-spacing-lg);

  @media (--breakpoint-medium-max) {
    flex-direction: column;
    gap: var(--st-sizing-xs);
  }
}

.innerContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (--breakpoint-small-max) {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--st-sizing-xs);
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-2xs);
  width: 100%;

  @media (--ds-breakpoint-md) {
    flex-direction: row;
    align-items: center;
    width: unset;
  }
}

.productImagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--ds-spacing-2xs);

  @media (--breakpoint-small-max) {
  }
}

.productImage {
  width: 5rem;
  height: 5rem;

  @media (--breakpoint-small-max) {
    width: 4rem;
    height: 4rem;
  }
}

.imagesPlaceholder {
  flex: 1;
}

.product {
  flex-shrink: 0;
  width: 5rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (--breakpoint-small-max) {
    width: 4rem;
    height: 4rem;
  }
}

.additionalProducts {
  background-color: var(--st-color-blue-dark);
  color: var(--st-color-white);
}

.unavailableProducts {
  background-color: var(--st-color-white);
  color: var(--st-color-grey-dark);
  border: 1px solid var(--st-color-grey-light);
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  overflow-wrap: break-word;
  max-width: 100%;
}

.largeTitle {
  margin-bottom: var(--st-sizing-sm);
}

.editControlsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.ghostButtonSeparator {
  border: 1px solid var(--st-color-grey-light);
  height: 1.125rem;
  margin: 0 var(--st-sizing-xxs);
}
