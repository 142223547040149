.spinnerWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--st-sizing-xl);

  @media (--breakpoint-small-max) {
    gap: 0;
  }
}
