.contentContainer {
  margin: var(--st-sizing-md) 0px;
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-xs);
}
