/* 
          MOBILE
          
          +---------+---------+---------+
          |         |         Item name |Gift card  
          +---------+---------+---------+   
          |   Image |               Qty |
          +---------+---------+---------+
          |         |              Price|
          +---------+---------+---------+ 
          
          DESKTOP 
          +---------+---------+---------+---------+---------+---------+---------+---------+
          |Image              Item name           |                   |Qty      |    Price|   Gift card
          +---------+---------+---------+---------+---------+---------+---------+---------+
           */

.digitalGifting {
  grid-template-areas: 'Image Name Name Name . . Quantity Price';
  grid-template-columns: repeat(8, 1fr);
  align-items: center;

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(3, 1fr);
    justify-items: end;
    grid-template-areas:
      'Image Name Name'
      'Image Quantity Quantity'
      'Image Price Price';
  }
}
