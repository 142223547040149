.addressContainer {
  display: flex;
  flex-direction: column;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.defaultsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--st-sizing-xs);
  @media (--breakpoint-small-max) {
    align-items: flex-end;
    margin-top: var(--st-sizing-ms);
  }
}

.buttonContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.iconButton {
  display: flex;
  align-items: center;
  margin: var(--st-sizing-4xs);
}

.disabled {
  cursor: default;
  pointer-events: none;
}
