.container {
  display: flex;
  flex-direction: column;
  background-color: var(--st-color-white);
  padding: var(--st-sizing-lg);
  border-radius: var(--st-sizing-4xs);
  margin-bottom: var(--st-sizing-lg);
  @media (--breakpoint-medium-max) {
    margin-bottom: unset;
    padding: var(--st-sizing-sm);
  }
}

.headerText {
  padding-bottom: var(--st-sizing-xs);
}

.packageInformationRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--st-sizing-md);
  @media (--breakpoint-medium-max) {
    flex-direction: column;
  }
}

.packageInformationSection {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sectionContents {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (--breakpoint-large-max) {
    flex-direction: column;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (--breakpoint-medium-max) {
    justify-content: unset;
  }
}

.divider {
  width: 1px;
  background-color: var(--st-color-grey-light);
  margin: 0 var(--st-sizing-xxl);
  @media (--breakpoint-medium-max) {
    height: 1px;
    width: 100%;
    margin: var(--st-sizing-md) 0;
  }
}

.inputWrapper {
  flex-shrink: 0;
  max-width: unset;
  min-width: unset;
  width: unset;
  margin-left: var(--st-sizing-xs);
  @media (--breakpoint-large-max) {
    width: unset;
    margin-top: var(--st-sizing-xs);
    margin-left: unset;
  }
}

.inputWrapperMaxWidth {
  max-width: 9rem;
}

.inputWrapperMobileWidth {
  width: 50%;
  @media (--breakpoint-large-max) {
    width: 100%;
  }
}
