.wrapper {
  display: flex;
  flex-direction: column;
}

.item {
  display: grid;
  padding: var(--st-sizing-3xs) var(--st-sizing-xl);
  grid-gap: var(--st-sizing-ms);
  grid-template-columns: max(15%, var(--st-sizing-xxl)) 1fr;

  @media (--breakpoint-medium-max) {
    padding: var(--st-sizing-3xs) var(--st-sizing-ms);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--st-color-grey-extra-light);
  }
}
