.FAQsContainer {
  margin: var(--st-sizing-lg) 0;
  @media (--breakpoint-medium-max) {
    margin: var(--st-sizing-sm) 0;
  }
}

.FAQWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--st-sizing-xs);
}

.FAQWrapper:last-child {
  margin-bottom: unset;
}

.mobileFAQWrapper:not(:last-child) {
  border-bottom: 1px solid var(--st-color-grey-light);
}

.chevronIcon {
  margin-left: var(--st-sizing-xs);
  flex-shrink: 0;
  transition: transform 150ms ease-in-out;
  transform: rotate(90deg);

  &.active {
    transform: rotate(270deg);
  }
}
