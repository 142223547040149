.orderCancelBarContainer {
  margin-top: var(--st-sizing-lg);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (--breakpoint-medium-max) {
    flex-direction: column;
    margin-top: var(--st-sizing-sm);
    align-items: unset;
  }
}

.unavailableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: var(--st-sizing-lg);
  padding: 0 var(--st-sizing-lg);
}

.note {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: var(--st-sizing-xs);

  &.warning {
    background-color: var(--st-color-yellow-extra-light);
  }

  &.success {
    background-color: var(--st-color-green-extra-light);
  }

  @media (--breakpoint-medium-max) {
    align-items: flex-start;
    margin-right: 0;
  }
}

.icon {
  flex-shrink: 0;
  margin-right: var(--st-sizing-xs);
}

.button {
  flex-shrink: 0;

  @media (--breakpoint-medium-max) {
    margin: var(--st-sizing-sm);
    margin-bottom: 0;
  }
}
