.container {
  background-color: var(--st-color-white);
  padding: 0 var(--st-sizing-lg);
  border-radius: var(--st-sizing-4xs);
  margin-bottom: var(--st-sizing-lg);

  @media (--breakpoint-medium-max) {
    padding: 0 var(--st-sizing-sm);
  }
}

.backContainer {
  background-color: var(--st-color-white);
  border-radius: var(--st-sizing-4xs);
  padding: var(--st-sizing-sm) var(--st-sizing-lg) 0 var(--st-sizing-lg);

  @media (--breakpoint-medium-max) {
    margin: 0 0 var(--st-sizing-sm);
    padding: var(--st-sizing-xs) var(--st-sizing-sm);
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  
  @media (--breakpoint-medium-max) {
    flex-direction: column;
  }
}

.barContentItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: start;
  padding: var(--st-sizing-lg) 0 var(--st-sizing-lg) var(--st-sizing-lg);
  border-left: 1px solid var(--st-color-grey-extra-light);
  border-right: unset;

  @media (--breakpoint-medium-max) {
    align-items: unset;
    margin: 0 calc(var(--st-sizing-sm) * -1);
    padding: var(--st-sizing-sm);
    border-left: unset;
    border-right: unset;
    border-bottom: 2px solid var(--st-color-grey-extra-light);
  }
}

.borderRight {
  padding: var(--st-sizing-lg) var(--st-sizing-lg) var(--st-sizing-lg) 0;
  border-left: unset;
  border-right: 1px solid var(--st-color-grey-extra-light);

  @media (--breakpoint-medium-max) {
    padding: var(--st-sizing-sm);
    border-left: unset;
    border-right: unset;
  }
}

.barHeader {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--st-color-grey-extra-light);
  margin: 0 calc(var(--st-sizing-lg) * -1);
  padding: var(--st-sizing-lg);

  @media (--breakpoint-medium-max) {
    margin: 0 calc(var(--st-sizing-sm) * -1);
    padding: var(--st-sizing-xs);
  }
}

.barHeaderItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
}

.infoBox {
  max-width: 50%;
  padding: var(--st-sizing-xs);
  background-color: var(--st-color-grey-extra-light);

  @media (--breakpoint-large-max) {
    max-width: 100%;
  }
}

.courierInfoContainer {
  display: flex;
  flex-direction: column;
}

.linkContents {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  @media (--breakpoint-large-max) {
    flex-direction: column;
  }
}
