/* 
  DESKTOP
    +---------+---------+---------+---------+---------+---------+---------+---------+
    |Image    |Item name                    | Code              | Qty      OP Price | Standard
    +---------+---------+---------+---------+---------+---------+---------+---------+

  MOBILE
    +---------+---------+---------+---------+---------+
    |                   |                   Item name |
    +---------+---------+---------+---------+---------+
    |                   |         |      Product code |Standard                           
    +---------+---------+---------+---------+---------+
    |      Image        |         |               Qty |
    +---------+---------+--------+----------+---------+
    |                             |            OPrice |
    |                                           Price |
    +---------+---------+--------+----------+---------+ */

.standard {
  grid-template-areas: 'Image Name Name Name Code Code Quantity Price';
  grid-template-columns: repeat(8, 1fr);
  align-items: center;

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(5, 1fr);
    justify-items: end;
    grid-template-areas:
      'Image Image Name Name Name'
      'Image Image . Code Code'
      'Image Image . Quantity Quantity'
      'Image Image . Price Price';
  }
}

.product-details-container {
  grid-area: Name;
  display: flex;
  flex-direction: column;
  width: unset;
}
