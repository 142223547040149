.minifigureContainer {
  display: flex;
  overflow: hidden;
  width: 55%;
  height: 11.25rem;
  margin-bottom: calc(var(--st-sizing-xxl) * -1);
  margin-right: calc(var(--st-sizing-4xl) * -1);
  margin-left: var(--st-sizing-sm);
}

.vipCardTop {
  border-radius: var(--st-sizing-4xs) var(--st-sizing-4xs) 0 0;
  display: flex;
  justify-content: space-between;
  background-image: linear-gradient(
    0deg var(--st-color-grey-light) var(--st-color-grey-extra-light)
  );
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: var(--st-sizing-xs);
  @media (--breakpoint-small-max) {
    margin-right: var(--st-sizing-md);
  }
}

.vipCardBottom {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}

.vipCardContainer {
  display: flex;
  flex-direction: column;
  margin-top: 3.438rem;
  @media (--breakpoint-small-max) {
    margin-top: var(--st-sizing-xs);
  }
}

.titleContainer {
  display: flex;
  @media (--breakpoint-small-max) {
    margin-top: var(--st-sizing-xs);
  }
}

.vipCardLinksContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--st-color-vip-blue);
  justify-content: center;
  padding: var(--st-sizing-xs);
}

.styledListBullet {
  list-style-type: disc;

  &:before {
    display: inline-block;
    list-style-position: outside;
    font-size: var(--st-sizing-s0);
  }

  @media (--breakpoint-medium-max) {
    &:before {
      font-size: var(--st-sizing-s0);
    }
    font-size: var(--st-sizing-s0);
  }
  color: var(--st-color-white);
  margin: var(--st-sizing-xs) var(--st-sizing-xs) var(--st-sizing-xs)
    var(--st-sizing-md);
}
