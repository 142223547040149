.container {
  background-color: var(--st-color-white);
  margin: var(--st-sizing-lg) 0;
  @media (--breakpoint-medium-max) {
    margin: var(--st-sizing-sm) 0;
  }
}

.statusContainer {
  padding: var(--st-sizing-lg);
  display: flex;
  flex-direction: column;
  @media (--breakpoint-medium-max) {
    flex-direction: row;
    align-items: center;
  }
}

.header {
  padding: var(--st-sizing-lg);
  border-top: 1px solid var(--st-color-grey-light);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (--breakpoint-medium-max) {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
}
