.privacySetting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-spacing-sm);
}

.description {
  margin: 0;
}

.error {
  display: block;
  width: 100%;
  color: var(--ds-color-text-negative);
  background-color: var(--ds-color-layer-negative-subdued);
  padding: var(--ds-spacing-3xs);
  border: var(--ds-border-width-xs) solid
    var(--ds-color-border-negative-default);
  border-radius: var(--ds-border-radius-sm);
}
