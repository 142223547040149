.skuValue {
  color: var(--ds-color-text-subdued);
  font-size: var(--ds-size-2xs);
  text-align: right;
  margin: var(--ds-size-4xs) 0 var(--ds-size-4xs) 0;

  @media (--ds-breakpoint-md) {
    text-align: left;
  }
}
