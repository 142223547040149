.styledBanner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: left;
  background: var(--st-color-yellow-extra-light);
  width: 100%;
  padding: var(--st-sizing-xs);
  margin-right: var(--st-sizing-xs);
  @media (--breakpoint-small-max) {
    margin-right: unset;
    margin-bottom: var(--st-sizing-sm);
  }
}

.doubleOptInSaved {
  justify-content: center;
  background: var(--st-color-blue-extra-light);
}
