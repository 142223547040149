.minHeightContainer {
  align-items: center;
  display: inline-block;

  @media (--breakpoint-large-max) {
    min-height: unset;
  }
}

.checkboxWrapper {
  display: inline-block;
}

.miscPreferenceOptionContainer {
  display: inline-block;
  margin: var(--st-sizing-xxs) 0px;

  @media (--breakpoint-small-min) {
    width: 33.33%;
  }

  @media (--breakpoint-small-max) {
    width: 45%;
  }
}
