.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--st-sizing-xs);
}

.errorContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--st-color-white);
  padding: var(--st-sizing-lg);
  margin: var(--st-sizing-lg) 0;

  @media (--breakpoint-medium-max) {
    margin-top: var(--st-sizing-sm);
  }
}
