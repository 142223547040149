.container {
  padding: var(--st-sizing-sm) 0 var(--st-sizing-lg) 0;
  @media (--breakpoint-medium-max) {
    padding-top: 0;
    margin: 0 calc(var(--st-sizing-lg) * -1);
  }
}

.tabContainer ul {
  max-width: max-content;
  border-bottom: 1px solid var(--st-color-grey-light);

  @media (--breakpoint-medium-max) {
    margin: 0 var(--st-sizing-xs);
  }
}

.tabContainer li {
  min-width: fit-content;

  @media (--breakpoint-small-max) {
    min-width: auto;
  }
}
