.trackingLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--ds-spacing-md);
}

@media (--breakpoint-medium-max) {
  .trackingLinks {
    width: max-content;
    flex-direction: column;
    align-items: flex-start;
  }
}
