.orderStatusInfoContainer {
  flex-grow: 1;
}

.tooltipBody {
  width: 14rem; /* 224px */

  @media (--breakpoint-medium-min) {
    width: 16rem; /* 256px */
  }
}
