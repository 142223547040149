.styledContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--st-sizing-sm);
  padding-bottom: var(--st-sizing-sm);
}

.tickContainer {
  padding: var(--st-sizing-3xs);
  background-color: var(--st-color-blue);
  border-radius: 50%;
  margin-bottom: var(--st-sizing-3xs);
}
