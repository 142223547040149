.addAddressContainer {
  min-width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: var(--st-sizing-3xs) 0px;
  border: 1px dotted var(--st-color-grey-light);
  padding: var(--st-sizing-sm);
  min-height: 7.5rem;
  cursor: pointer;

  @media (--breakpoint-small-max) {
    min-height: unset;
    width: 100%;
  }
}
