.inputContainer {
  margin-bottom: var(--st-sizing-xs);
  margin-top: var(--st-sizing-4xs);
  min-width: 49%;
  @media (--breakpoint-small-max) {
    min-width: 100%;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: var(--st-sizing-xs);
}

.simple {
  margin-top: unset;
}

.inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: var(--st-sizing-xs);
}
