.wishlistProductBlock {
  display: flex;
  flex-direction: column;
  gap: var(--st-sizing-sm);
}

.sortDropdownContainer {
  align-self: flex-end;
  z-index: 2; /* Prevent the dropdown flowing under the delete button in a product leaf */
}
