.productRowContainer {
  padding: var(--st-sizing-xs) var(--st-sizing-lg);
  border-top: 1px solid var(--st-color-grey-light);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas: 'Image Name Name Sku Price Quantity QuantityInput Checkbox';
  align-items: center;
  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      'Image Image Name Name Name'
      'Image Image . Sku Sku'
      'Image Image . Quantity Quantity'
      'Image Image . Price Price'
      '. . . . .'
      'Checkbox Checkbox . QuantityInput QuantityInput';
  }
}

.productRowSection {
  text-align: left;
  @media (--breakpoint-medium-max) {
    &:last-child,
    &:nth-last-child(2) {
      margin-top: calc(var(--st-sizing-3xs) * -1);
    }
  }
}

.sku {
  grid-area: Sku;
  @media (--breakpoint-medium-max) {
    text-align: right;
  }
}

.name {
  grid-area: Name;
  @media (--breakpoint-medium-max) {
    text-align: right;
  }
}

.thumbnail {
  grid-area: Image;
  & img {
    max-width: 6rem;
    max-height: 6rem;
  }
}

.price {
  grid-area: Price;
  text-align: center;
  @media (--breakpoint-medium-max) {
    text-align: right;
  }
}

.quantity {
  grid-area: Quantity;
  text-align: center;
  @media (--breakpoint-medium-max) {
    text-align: right;
  }
}

.quantityInput {
  grid-area: QuantityInput;
}

.checkboxContainer {
  grid-area: Checkbox;
  text-align: right;
  justify-content: flex-end;
  @media (--breakpoint-medium-max) {
    justify-content: flex-start;
  }
}
