.headerContainer {
  padding: var(--st-sizing-md);
  padding-bottom: var(--st-sizing-xs);
}

.contentContainer {
  padding: 0 var(--st-sizing-md) var(--st-sizing-md) var(--st-sizing-md);
  display: flex;
  max-width: 28.125rem; /* 450px */

  @media (--breakpoint-small-max) {
    max-width: 100%;
  }
}

.buttonContainer {
  padding: 0 var(--st-sizing-md);
  display: flex;
  max-width: 100%;

  & button:first-child {
    margin-right: var(--st-sizing-ms);
    margin-bottom: var(--st-sizing-xs);
  }

  & button:last-child {
    margin-bottom: var(--st-sizing-xs);
  }

  @media (--breakpoint-small-max) {
    flex-direction: column;
  }
}
