.minifigureContainer {
  display: flex;
  width: 25%;
  overflow: hidden;
  height: 100%;
  margin: var(--st-sizing-sm) var(--st-sizing-sm);
  align-self: center;

  @media (--breakpoint-tiny-max) {
    flex: 0%;
  }
}

.vipCardRight {
  display: flex;
  flex-direction: column;
  flex: 50%;
}

.cardLinks {
  display: flex;
  justify-content: space-evenly;
  padding-top: var(--st-sizing-sm);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.leftCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 75%;
  margin: var(--st-sizing-xxs) var(--st-sizing-xs) var(--st-sizing-xxs)
    var(--st-sizing-xxs);

  @media (--breakpoint-tiny-max) {
    flex: 100%;
  }
}
