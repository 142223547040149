.inputs {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  @media (--breakpoint-small-max) {
    margin-top: 0px;
    flex-direction: column;
  }
}

.inputContainer {
  min-width: 49%;
  margin-bottom: var(--st-sizing-sm);

  @media (--breakpoint-small-max) {
    min-width: 100%
  }
}

.countrySelectorContainer {
  width: 100%;
  margin-bottom: var(--st-sizing-xs);
}

.manualFooter {
  margin-top: var(--st-sizing-xs);
  width: 100%;
  display: flex;
  justify-content: flex-end;
}