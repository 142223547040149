.container {
  padding: var(--st-sizing-ms);
  max-width: 28rem;
  @media (--breakpoint-medium-max) {
    max-width: unset;
  }
}

.section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--st-color-grey-light);
  padding-bottom: var(--st-sizing-xs);
  margin-bottom: var(--st-sizing-xs);
  &__resetBottom {
    border-bottom: unset;
    padding-bottom: unset;
    margin-bottom: unset;
  }
}

.brickSection {
  padding-left: var(--st-sizing-xs);
  border-bottom: 1px solid var(--st-color-grey-light);
  padding-bottom: var(--st-sizing-xs);
  margin-bottom: var(--st-sizing-xs);
  &:last-child {
    padding-left: unset;
    border-bottom: unset;
    padding-bottom: unset;
    margin-bottom: unset;
  }
}

.buttonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--st-sizing-xs);
  @media (--breakpoint-medium-max) {
    flex-direction: column;
  }
}

.spinnerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--st-sizing-4xl);
}

.errorBar {
  display: flex;
  align-items: flex-start;
  background-color: var(--st-color-red-extra-light);
  border: 1px solid var(--st-color-red);
  border-radius: var(--st-sizing-4xs);
  padding: var(--st-sizing-ms);
  margin-top: var(--st-sizing-xs);
}

.flexButton {
  flex: 1;
}
