/*
  DESKTOP
    +---------+---------+---------+---------+---------+---------+---------+---------+
    |Image    |Item name                    | Code              | Qty      OP Price | MinifigureFactory
    +---------+---------+---------+---------+---------+---------+---------+---------+
    |Image    |Item name                    | RefId             | Qty      OP Price |
    +---------+---------+---------+---------+---------+---------+---------+---------+

  MOBILE
    +---------+---------+---------+---------+---------+
    |      Image        |                   Item name |
    +---------+---------+---------+---------+---------+
    |      Image        |         |      Product code |MinifigureFactory
    +---------+---------+---------+---------+---------+
    |      Image        |         |             RefId |
    +---------+---------+---------+---------+---------+
    |      Image        |         |               Qty |
    +---------+---------+--------+----------+---------+
    |                             |            OPrice |
    |                                           Price |
    +---------+---------+--------+----------+---------+ */

.standard {
  grid-template-areas:
    'Image Name Name Name Code Code Quantity Price'
    'Image Name Name Name RefId RefId Quantity Price';
  grid-template-columns: repeat(8, 1fr);
  align-items: baseline;

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(5, 1fr);
    justify-items: end;
    align-items: center;
    grid-template-areas:
      'Image Image Name Name Name'
      'Image Image . Code Code'
      'Image Image RefId RefId RefId'
      'Image Image . Quantity Quantity'
      'Image Image . Price Price';
  }
}
