.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: var(--st-sizing-sm) 0px;
  padding: var(--st-sizing-md);
  background-color: var(--st-color-grey-extra-light);
  border-radius: 5px;
}
