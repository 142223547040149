.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.buttonContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cardDetails {
  display: flex;
  flex-direction: column;
}
