.open {
  animation: open 0.5s;
}

.pabHeaderAccordion {
  padding-bottom: var(--st-sizing-sm);
}

@keyframes open {
  0% {
    display: none;
    transform: translate3d(0, -1rem, 0);
    opacity: 0;
  }
  1% {
    display: block;
    transform: translate3d(0, -1rem, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

