.contentContainer {
  @media (--breakpoint-medium-max) {
    padding-bottom: var(--st-sizing-sm);
    margin-bottom: var(--st-sizing-sm);
    border-bottom: 1px solid var(--st-color-grey-light);
  }
}

.barHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--st-color-grey-light);
  margin: 0 calc(var(--st-sizing-lg) * -1);
  padding: 0 var(--st-sizing-lg);
  @media (--breakpoint-medium-max) {
    margin: 0 calc(var(--st-sizing-sm) * -1);
    padding: 0 var(--st-sizing-sm);
  }
}

.orderSummaryItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paymentShippingContent {
  display: flex;
  flex-direction: column;
  @media (--breakpoint-medium-max) {
    padding-bottom: var(--st-sizing-sm);
    margin-bottom: var(--st-sizing-sm);
    border-bottom: 1px solid var(--st-color-grey-light);
  }
}

.paymentShippingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.barContents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: var(--st-sizing-lg) 0;
  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.container {
  background-color: var(--st-color-white);
  padding: 0 var(--st-sizing-lg) var(--st-sizing-sm) var(--st-sizing-lg);
  border-radius: var(--st-sizing-4xs);
  margin-bottom: var(--st-sizing-lg);
  @media (--breakpoint-medium-max) {
    margin-bottom: unset;
    padding: 0 var(--st-sizing-sm);
  }
}

.backContainer {
  background-color: var(--st-color-white);
  padding: var(--st-sizing-sm) var(--st-sizing-lg) 0 var(--st-sizing-lg);
  @media (--breakpoint-medium-max) {
    margin-bottom: var(--st-sizing-sm);
    padding: var(--st-sizing-xs) var(--st-sizing-sm);
  }
}
