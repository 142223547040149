.container {
  padding: var(--st-sizing-xxl) var(--st-sizing-3xl);

  @media (--breakpoint-medium-max) {
    padding: var(--st-sizing-xxl) var(--st-sizing-xl);
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--st-sizing-lg);
  text-align: center;
}

.tickContainer {
  width: 2.2rem;
  height: 2.2rem;
  background-color: var(--st-color-green);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: var(--st-sizing-lg);
}

.step {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  margin-left: calc(var(--st-sizing-ms) + 3.13rem);

  @media (--breakpoint-medium-max) {
    margin-left: unset;
  }
}

.stepIcon {
  margin-right: var(--st-sizing-ms);
  flex-shrink: 0;
}
