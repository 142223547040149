.additionalInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--breakpoint-medium-max) {
    flex-direction: column;
    align-items: flex-start;
  }
}
