.supportLinkContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.supportLinkRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: var(--st-sizing-xs) 0px;
  @media (--breakpoint-small-max) {
    flex-direction: column;
  }
}

.StyledImage {
  width: 6.62rem;
  height: 6.62rem;
  position: absolute;
  top: -1.563rem;
  right: -3.125rem;
  @media (--breakpoint-small-max) {
    right: -0.938rem;
  }
}

.styledHorizontalSeparator {
  height: 0px;
  border-bottom: 1px var(--st-color-grey-light) solid;
  margin-right: 50px;
  @media (--breakpoint-small-max) {
    margin-right: 4.375rem;
  }
}
