.productGroupContainer {
  padding: var(--st-sizing-lg);
  background-color: var(--st-color-white);
  border-radius: var(--st-sizing-4xs);
  margin-top: var(--st-sizing-lg);

  @media (--breakpoint-medium-max) {
    margin-top: var(--st-sizing-sm);
  }
}

.productGroupHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--st-color-grey-light);
  margin: 0 calc(var(--st-sizing-lg) * -1);
  padding: 0 var(--st-sizing-lg);
  padding-bottom: var(--st-sizing-lg);

  @media (--breakpoint-medium-max) {
    flex-direction: column;
    align-items: unset;
    gap: var(--st-sizing-xs);
  }
}

.productGroupDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.productGroupTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--st-sizing-3xs);
}
