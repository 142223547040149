.container {
  margin-top: var(--st-sizing-md);
  @media (--breakpoint-medium-max) {
    padding: 0 var(--st-sizing-xxs);
  }
}

.progressTrack {
  width: 100%;
  height: var(--st-sizing-3xs);
  background-color: var(--st-color-grey-light);
  border-radius: var(--st-sizing-3xs);
  position: relative;
}

.indicatorDotHolder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

/* span */
.dot {
  width: var(--st-sizing-3xs);
  height: 100%;
  background-color: var(--st-color-grey);
  border-radius: 100%;
}

.labelContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--st-sizing-xs);
}
