.backButton {
  @media (--breakpoint-medium-max) {
    margin-top: var(--ds-spacing-2xs);
    display: inline-block;
  }
}

.hideBreadcrumbsMobile {
  display: block;
  @media (--breakpoint-medium-max) {
    display: none;
  }
}
