.order {
  background-color: var(--st-color-grey-extra-light);
  padding: var(--ds-spacing-xs);
  border-radius: var(--ds-border-radius-sm);
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-md);

  @media (--ds-breakpoint-sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--ds-spacing-3xs);
  }
}

.orderDetailsContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-spacing-xs);

  @media (--ds-breakpoint-lg) {
    flex-direction: row;
    gap: var(--ds-spacing-md);
  }
}

.orderInfo {
  display: flex;
  flex-direction: column;
}

.orderStatus {
  display: flex;
  flex-direction: row;
  gap: var(--st-sizing-3xs);
}

.itemsInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-spacing-4xs);

  @media (--ds-breakpoint-md) {
    gap: var(--ds-spacing-2xs);
  }
}

.thumbnailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--ds-spacing-3xs);
}

.productThumbnail {
  width: 4rem;
  height: 4rem;

  @media (--ds-breakpoint-md) {
    width: 5rem;
    height: 5rem;
  }
}

.placeholderThumbnail {
  background-color: var(--ds-color-action-primary-enabled);
  color: var(--ds-color-text-inverse);
  display: flex;
  align-items: center;
  justify-content: center;
}
