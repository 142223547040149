.messageBox {
  background-color: var(--st-color-blue-extra-light);
  padding: var(--st-sizing-lg);
  margin: var(--st-sizing-lg) 0;
  display: flex;
  flex-direction: column;
  @media (--breakpoint-medium-max) {
    padding: var(--st-sizing-sm);
    margin-bottom: var(--st-sizing-sm) 0;
  }
}
