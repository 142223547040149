.noReturnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: var(--st-sizing-lg);
  background-color: var(--st-color-white);
  padding: var(--st-sizing-sm) var(--st-sizing-lg);
  border-radius: var(--st-sizing-4xs);

  @media (--breakpoint-medium-max) {
    border-radius: 0;
    padding: var(--st-sizing-sm);
  }
}

.noReturnDescription {
  margin-top: var(--st-sizing-xs);
}
