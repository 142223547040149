/* 
      MOBILE
    +---------+---------+---------+
    |Item name                    |
    +---------+---------+---------+
    |Product code       |    Price|   PAB header item
    +---------+---------+---------+ 
    
    
       DESKTOP
    +---------+---------+---------+---------+---------+---------+---------+---------+---------+
    |         |Item name                    | Code    |         |       Price       |         |   PAB header item
    +---------+---------+---------+---------+---------+---------+---------+---------+---------+
    
    
    
    
    */

.pabHeaderItem {
  grid-template-areas: 'Name Name Name Name Code . Price Price .';
  grid-template-columns: repeat(8, 1fr);

  & div {
    @media (--breakpoint-medium-max) {
      text-align: left;
    }
  }

  @media (--breakpoint-medium-max) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'Name Name Name'
      'Code Code Price';
  }
}
