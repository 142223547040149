.modalContent {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-spacing-sm);
}

.heading {
  margin: 0;
}

.description {
  margin: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--ds-spacing-3xs);

  @media (--ds-breakpoint-md) {
    flex-direction: row;
    align-items: center;
  }
}
