.pageWrapper {
  max-width: var(--st-dimension-max-width);
  margin: 0 auto;
  padding: var(--ds-spacing-2xs) var(--ds-spacing-xs);
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-xs);

  @media (--ds-breakpoint-md) {
    padding: var(--ds-spacing-sm) var(--ds-spacing-md);
    gap: var(--ds-spacing-md);
  }
}
