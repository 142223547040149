.addressesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--st-sizing-3xs);
  @media (--breakpoint-small-max) {
    flex-direction: column;
    align-items: unset;
  }
}

.checkboxContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--st-sizing-4xs);
  margin-top: var(--st-sizing-3xs);
}
