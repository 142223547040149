.container {
  padding: var(--st-sizing-sm) 0 var(--st-sizing-lg) 0;
  margin: 0 calc(var(--st-sizing-lg) * -1);
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--st-sizing-lg) var(--st-sizing-lg) 0;
  min-height: 25rem;
  text-align: center;
}

.backContainer {
  border-radius: var(--st-sizing-4xs);
  padding: var(--st-sizing-sm) var(--st-sizing-xl);

  @media (--breakpoint-medium-max) {
    margin: 0 0 var(--st-sizing-sm);
    padding: var(--st-sizing-xs) var(--st-sizing-sm);
  }
}
