.wrapper {
  background-color: var(--ds-color-layer-default);
}

.border {
  padding: var(--ds-spacing-5xs);
  border: 1px solid var(--ds-color-border-subdued);
}

.fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
